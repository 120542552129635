export const titleA = {
    id:'ContactTitle',
    title:'About Suwik Foods',
    titleA:'Feed the world'
};

export const homeObjZ = {
    id:'ContactTitle',
    title:'Contacting Suwik ',
};


