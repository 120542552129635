export const homeObjA = {
    id:'about',
    title:'Contacting Zicofe',
    title1:'Supply and general inquiries',
    title2:'Cooporate Address',
    title3:'Corporate Address',
    text:'We supply and export agricultural commodities to all destinations globally.',
    text2:'Reach out to us for a long term stable commodities supply partner.',
    text3:'Suwik Investments',
    phone:'Call us on +(256) 762 764 298',
    email:'Email us at :-wilson@suwik.xyz',
    email1:'',
    email2:"carolddembe@suwik.xyz",
    street:'Plot 104, 5th street Industrial Area',
    city:'Kampala, Uganda'
};

